import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK } from 'react-admin';

export default (type, params) => {
    if (type === AUTH_LOGIN) {
        const { tenant, username, password } = params;
        const request = new Request('https://' + tenant +'.' + process.env.REACT_APP_STAGE + '.newstore.net/v0/token', {
            method: 'POST',
            body: 'grant_type=password&username=' + username + '&password=' + password,
            headers: new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' }),
        })
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({ access_token }) => {
                localStorage.setItem('token', access_token);
                localStorage.setItem('tenant', tenant);
            });
    }
    if (type === AUTH_LOGOUT) {
        localStorage.removeItem('token');
        localStorage.removeItem('tenant');
    }
    if (type === AUTH_ERROR) {
        const status  = params.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('token');
            localStorage.removeItem('tenant');
            return Promise.reject();
        }
    }
    if (type === AUTH_CHECK) {
        return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
    }
    return Promise.resolve();
}
