import React from 'react';
import { List, Datagrid, TextField, ChipField, BooleanField, DateField, Filter, TextInput, SelectInput, BooleanInput } from 'react-admin';

const AlertFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Alert Type" source="alert_type" alwaysOn />
        <SelectInput source="priority" choices={[
          { id: 'P1', name: 'P1' },
          { id: 'P2', name: 'P2' },
          { id: 'P3', name: 'P3' },
          { id: 'P4', name: 'P4' },
          { id: 'P5', name: 'P5' },
        ]} alwaysOn />
        <BooleanInput label="Acknowledged" source="acknowledged" alwaysOn />
    </Filter>
);

export const AlertList = props => (
    <List {...props} sort={{ field: 'createdAt', order: 'DESC' }} filters={<AlertFilter />}>
        <Datagrid rowClick="edit">
            <TextField source="alias" />
            <TextField source="alert_type" />
            <ChipField source="priority" />
            <TextField source="count" />
            <DateField source="createdAt" showTime/>
            <BooleanField source="acknowledged" />
            <TextField source="message" />
        </Datagrid>
    </List>
);
