import React from 'react';
import { List, Filter, Datagrid, TextField, ChipField, DateField, SelectInput, ArrayField, SingleFieldList, DateInput } from 'react-admin';

const ImportFilter = (props) => (
    <Filter {...props}>
        <SelectInput source="entities" choices={[
          { id: 'products', name: 'Products' },
          { id: 'categories', name: 'Categories' },
          { id: 'prices', name: 'Prices' },
          { id: 'availabilities', name: 'Availabilities' },
        ]} alwaysOn />
        <SelectInput source="state" choices={[
          { id: 'received', name: 'Received' },
          { id: 'waiting', name: 'Waiting' },
          { id: 'processing', name: 'Processing' },
          { id: 'failed', name: 'Failed' },
          { id: 'finished', name: 'Finished' },
          { id: 'cleaning', name: 'Cleaning' },
        ]} alwaysOn />
        <DateInput label="Completed" source="completed_at" alwaysOn />
    </Filter>
);

export const ImportList = props => (
    <List {...props} sort={{ field: 'received_at', order: 'DESC' }} filters={<ImportFilter />}>
        <Datagrid rowClick="edit">
            <TextField source="id" sortable={false} />
            <TextField source="name" sortable={false} />
            <ArrayField source="entities">
                <SingleFieldList>
                    <ChipField source="name" />
                </SingleFieldList>
            </ArrayField>
            <DateField source="received_at" showTime/>
            <DateField source="completed_at" showTime/>
            <ChipField source="state" />
        </Datagrid>
    </List>
);
