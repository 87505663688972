import React, {
    Component,
    HtmlHTMLAttributes,
} from 'react';
import { connect } from 'react-redux';
import { userLogin } from 'react-admin';
import classnames from 'classnames';
import { MuiThemeProvider, createMuiTheme, withStyles, createStyles, WithStyles, Theme, } from '@material-ui/core/styles';
import Notification from 'ra-ui-materialui/lib/layout/Notification';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CardActions from '@material-ui/core/CardActions';
import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import LockIcon from '@material-ui/icons/Lock';

const styles = (theme: Theme) =>
    createStyles({
        main: {
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            height: '1px',
            alignItems: 'center',
            justifyContent: 'flex-start',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
        },
        card: {
            minWidth: 300,
            marginTop: '6em',
        },
        logo: {
            margin: '1em',
            display: 'flex',
            justifyContent: 'center',
        },
        img: {
          width: 300,
          maxWidth: '100%',
          height: 'auto',
          objectFit: 'contain',
        },
        avatar: {
            margin: '1em',
            display: 'flex',
            justifyContent: 'center',
        },
        icon: {
            backgroundColor: theme.palette.secondary[500],
        },
        form: {
            padding: '0 1em 1em 1em',
        },
        input: {
            marginTop: '1em',
            width: '300px',
        },
        inputField: {
            width: '300px',
        },
        button: {
            width: '100%',
        },
    });

class MyLoginPage extends Component<
    Props & WithStyles<typeof styles> & HtmlHTMLAttributes<HTMLDivElement>
> {
    theme = createMuiTheme(this.props.theme);
    containerRef = React.createRef(HTMLDivElement);
    backgroundImageLoaded = false;

    updateBackgroundImage = () => {
        if (!this.backgroundImageLoaded && this.containerRef.current) {
            const { backgroundImage } = this.props;
            this.containerRef.current.style.backgroundImage = `url(${backgroundImage})`;
            this.backgroundImageLoaded = true;
        }
    };

    // Load background image asynchronously to speed up time to interactive
    lazyLoadBackgroundImage() {
        const { backgroundImage } = this.props;

        if (backgroundImage) {
            const img = new Image();
            img.onload = this.updateBackgroundImage;
            img.src = backgroundImage;
        }
    }

    componentDidMount() {
        this.lazyLoadBackgroundImage();
    }

    componentDidUpdate() {
        if (!this.backgroundImageLoaded) {
            this.lazyLoadBackgroundImage();
        }
    }

    submit = (e) => {
        e.preventDefault();
        // gather your data/credentials here
        var tenant = e.target.tenant.value;
        var username = e.target.username.value;
        var password = e.target.password.value;
        const credentials = {tenant, username, password};
        //const credentials = { e.target.tenant.value, e.target.username.value, e.target.password.value };

        // Dispatch the userLogin action (injected by connect)
        this.props.userLogin(credentials);
    }

    render() {
        const {
            backgroundImage,
            classes,
            className,
            loginForm,
            ...rest
        } = this.props;

        return (
            <MuiThemeProvider theme={this.props.theme}>
                <div
                    className={classnames(classes.main, className)}
                    {...rest}
                    ref={this.containerRef}
                >
                  <Card className={classes.card}>
                      <div className={classes.logo}>
                          <img src="/newstore-primary.png" className={classes.img} alt="NewStore Operations Monitoring"/>
                      </div>
                      <div className={classes.avatar}>
                          <Avatar className={classes.icon}>
                              <LockIcon />
                          </Avatar>
                      </div>
                      <form onSubmit={this.submit}>
                          <div className={classes.form}>
                            <div className={classes.input}>
                                <TextField
                                    autoFocus
                                    id="tenant"
                                    name="tenant"
                                    label="Tenant"
                                    className={classes.inputField}
                                />
                            </div>
                            <div className={classes.input}>
                                <TextField
                                    id="username"
                                    name="username"
                                    label="Username"
                                    className={classes.inputField}
                                />
                            </div>
                            <div className={classes.input}>
                                <TextField
                                    id="password"
                                    name="password"
                                    label="Password"
                                    type="password"
                                    className={classes.inputField}
                                />
                            </div>
                          </div>
                          <CardActions>
                              <Button
                                      variant="raised"
                                      type="submit"
                                      color="primary"
                                      className={classes.button}
                                  >
                                      Login
                              </Button>
                          </CardActions>
                      </form>
                  </Card>
                  <Notification />
                </div>
            </MuiThemeProvider>
        );
    }
};

const MyEnhancedLogin = withStyles(styles)(MyLoginPage);

export default connect(undefined, { userLogin })(MyEnhancedLogin);
