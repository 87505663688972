import React from 'react';
import { List, Datagrid, TextField, ChipField } from 'react-admin';

export const Overview = props => (
    <List {...props} bulkActionButtons={false}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="count" />
            <ChipField source="status" />
        </Datagrid>
    </List>
);
