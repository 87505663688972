import React from 'react';
import { Admin, Resource, fetchUtils } from 'react-admin';
import { AlertList } from './alerts';
import { ImportList } from './imports';
import { Overview } from './overview';
import MyLoginPage from './MyLoginPage';
import authProvider from './authProvider';
import jsonServerProvider from 'ra-data-json-server';

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    options.headers.set('X-Tenant', localStorage.getItem('tenant'));
    options.headers.set('X-Token', localStorage.getItem('token'));

    return fetchUtils.fetchJson(url, options);
}

const dataProvider = jsonServerProvider(process.env.REACT_APP_DATAPROVIDER, httpClient);
const App = () => (
    <Admin dataProvider={dataProvider} authProvider={authProvider} loginPage={MyLoginPage}>
        <Resource name="overview" options={{ label: 'Alert Types' }} list={Overview} />
        <Resource name="alerts" list={AlertList} />
        <Resource name="imports" list={ImportList} />
    </Admin>
);

export default App;
